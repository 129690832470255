import React from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { InView } from "react-intersection-observer"
import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer"
import { TextContainer } from "../components/textContainer"

const Container = styled.section`
  overflow: hidden;
  margin-top: 3rem;
  background: #4c4c4c;
  color: #fff;

  &:first-child {
    margin-top: 0;
  }
`

const InnerContainer = styled(WidthContainer)`
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`

const Content = styled.div`
  @media screen and (min-width: 768px) {
    width: 45%;
    padding-right: 0.5rem;
  }
`

const Title = styled.h2`
  white-space: pre-line;
  text-transform: uppercase;
  line-height: 1.4;
  font-size: 2.75rem;
  position: relative;
  margin: 0 0 1.5rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 4rem;
    background: #fff;
    height: 3px;
    bottom: -0.5rem;
  }
`

const Subtitle = styled.h3`
  margin: 1rem 0 0;
  font-weight: 500;
  font-size: 1.75rem;
  text-transform: uppercase;

  &:first-child {
    margin-top: 0;
  }
`

const Text = styled.div`
  //margin-top: 0.5rem;

  &:first-child {
    margin-top: 0;
  }
  p {
    margin: 1rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
`

const Results = styled.div`
  margin-top: 2rem;
  @media screen and (min-width: 768px) {
    width: 55%;
    padding-left: 0.5rem;
    margin-top: 0;
  }
`

const ContentTextContainer = styled(TextContainer)`
  padding-left: 1rem;
  max-width: inherit;

  @media (min-width: 576px) {
    padding-left: 2rem;
  }
`

const Result = styled.div`
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
`

const NumberContainer = styled.div`
  position: relative;
  display: inline-block;
`

const Number = styled.div`
  position: relative;
  margin-right: 1rem;
  margin-left: 0.25rem;
  font-size: 2.5rem;
  font-weight: 700;
`

const NumberBackground = styled.div`
  position: absolute;
  top: 45%;
  bottom: 10%;
  left: 0;
  width: ${props => (props.active ? "100%" : "0")};
  background: linear-gradient(
    130deg,
    ${props => props.theme.colorPrimary} 0,
    ${props => props.theme.colorSecondary} 100%
  );
  transition: width 500ms ease-in-out;
`

const Description = styled.div`
  font-size: 1.25rem;
  line-height: 1;
`

const SectionResult = ({ section }) => {
  const { title, subtitle, text, projectResults } = section

  return (
    <Container>
      <InnerContainer>
        <Content>
          <ContentTextContainer>
            {title && <Title>{title.title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            {text && <Text>{documentToReactComponents(text.json)}</Text>}
          </ContentTextContainer>
        </Content>
        <Results>
          <ContentTextContainer>
            {projectResults &&
              projectResults.map((projectResult, index) => {
                const { number, description } = projectResult
                return (
                  <InView key={index} triggerOnce threshold={1}>
                    {({ inView, ref }) => (
                      <Result ref={ref}>
                        <NumberContainer>
                          <NumberBackground active={inView} />
                          <Number>{number}</Number>
                        </NumberContainer>
                        <Description>{description}</Description>
                      </Result>
                    )}
                  </InView>
                )
              })}
          </ContentTextContainer>
        </Results>
      </InnerContainer>
    </Container>
  )
}

export default SectionResult
