import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import styled from "styled-components"

import Layout from "../components/layout"
import Hero from "../project/hero"
import SectionStandard from "../project/SectionStandard"
import SectionQuote from "../project/SectionQuote"
import SectionResult from "../project/SectionResult"

const ProjectSections = styled.div``

const ProjectPostPage = ({
  data: {
    site,
    contentfulHeader,
    contentfulFooter,
    contentfulProjectsPage,
    contentfulProject,
  },
  uri,
}) => {
  const {
    pageShortTitle,
    pageTitle,
    pageDescription: { pageDescription },
    pageImage,
    heroImage,
    heroText,
    projectTitle,
    projectSubtitle,
    projectMetas,
    sections,
  } = contentfulProject
  const { siteName } = contentfulHeader
  const { siteMetadata } = site

  return (
    <Layout
      header={contentfulHeader}
      footer={contentfulFooter}
      pageMeta={{ pageTitle, pageDescription, pageImage, uri }}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: siteName,
                item: `${siteMetadata.siteUrl}`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: contentfulProjectsPage.pageShortTitle,
                item: `${siteMetadata.siteUrl}/projects`,
              },
              {
                "@type": "ListItem",
                position: 3,
                name: pageShortTitle,
              },
            ],
          })}
        </script>
      </Helmet>
      <Hero
        image={heroImage}
        title={projectTitle}
        subtitle={projectSubtitle}
        text={heroText}
        projectMetas={projectMetas}
      />
      <ProjectSections>
        {sections &&
          sections.map((section, index) => {
            switch (section.__typename) {
              case "ContentfulProjectSectionStandard":
                return <SectionStandard section={section} key={index} />
              case "ContentfulProjectSectionQuote":
                return <SectionQuote section={section} key={index} />
              case "ContentfulProjectSectionResult":
                return <SectionResult section={section} key={index} />
              default:
                return null
            }
          })}
      </ProjectSections>
    </Layout>
  )
}

export default ProjectPostPage

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulHeader {
      ...HeaderInformation
    }
    contentfulFooter {
      ...FooterInformation
    }
    contentfulProjectsPage {
      pageShortTitle
    }
    contentfulProject(slug: { eq: $slug }) {
      pageShortTitle
      pageTitle
      pageDescription {
        pageDescription
      }
      pageImage {
        localFile {
          childImageSharp {
            fixed(width: 1200) {
              width
              height
              src
            }
          }
        }
      }
      projectTitle
      projectSubtitle
      heroImage {
        description
        localFile {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      heroText {
        json
      }
      projectMetas {
        title
        text {
          json
        }
#        images {
#          description
#          localFile {
#            childImageSharp {
#              fixed(width: 160) {
#                ...GatsbyImageSharpFixed
#              }
#            }
#          }
#        }
      }
      sections {
        ... on ContentfulProjectSectionStandard {
          title {
            title
          }
          subtitle
          text {
            json
          }
          showCaption
          images {
            description
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        ... on ContentfulProjectSectionQuote {
          quote {
            json
          }
          author {
            author
          }
        }
        ... on ContentfulProjectSectionResult {
          title {
            title
          }
          subtitle
          text {
            json
          }
          projectResults {
            number
            description
          }
        }
      }
    }
  }
`
