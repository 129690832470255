import React from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image/withIEPolyfill"

import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer"
import { TextContainer } from "../components/textContainer"

const Container = styled.section`
  overflow: hidden;
  margin-top: 3rem;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 3rem;
  }
`

const ImagesContainer = styled.div``

const ImageContainer = styled.div`
  position: relative;
`

const ImageCaptionContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`

const ImageCaption = styled.div``

const Content = styled(WidthContainer)`
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    margin-top: 3rem;
  }
`

const HeadingContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 45%;
    padding-right: 0.5rem;
  }
`

const Title = styled.h2`
  font-size: 1.5rem;
  white-space: pre-line;
  text-transform: uppercase;
  line-height: 1.4;
  margin: 0 0 0.5rem;
  color: ${props => props.theme.textColorEmphasis};
  position: relative;

  &:not(:only-child) {
    margin-bottom: 1.5rem;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 4rem;
      background: ${props => props.theme.textColorEmphasis};
      height: 3px;
      bottom: -0.5rem;
    }
  }
`

const Subtitle = styled.h3`
  margin: 1rem 0 0;
  font-weight: 500;

  &:first-child {
    margin-top: 0;
  }
`

const ContentContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 55%;
    padding-left: 0.5rem;
  }
`

const ContentTextContainer = styled(TextContainer)`
  padding-left: 0;
  max-width: inherit;

  @media screen and (min-width: 576px) {
    max-width: 620px;
    padding-left: 2rem;
  }

  @media screen and (min-width: 768px) {
    padding-left: 1rem;
  }
`

const Text = styled.div`
  //margin-top: 0.5rem;

  p {
    margin: 1rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
`

const SectionStandard = ({ section }) => {
  const { images, showCaption, title, subtitle, text } = section

  return (
    <Container>
      <WidthContainer>
        {images && (
          <ImagesContainer>
            {images.map((image, index) => {
              return (
                <ImageContainer key={index}>
                  <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    alt={image.description}
                  />
                  {showCaption && (
                    <ImageCaptionContainer>
                      <ImageCaption>{image.description}</ImageCaption>
                    </ImageCaptionContainer>
                  )}
                </ImageContainer>
              )
            })}
          </ImagesContainer>
        )}
        <Content>
          <HeadingContainer>
            <ContentTextContainer>
              {title && <Title>{title.title}</Title>}
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </ContentTextContainer>
          </HeadingContainer>
          <ContentContainer>
            <ContentTextContainer>
              {text && <Text>{documentToReactComponents(text.json)}</Text>}
            </ContentTextContainer>
          </ContentContainer>
        </Content>
      </WidthContainer>
    </Container>
  )
}

export default SectionStandard
