import React from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image/withIEPolyfill"

import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer"
import { openLinkInNewTab } from "../config/richText"
// import { TextContainer } from "../components/textContainer"

const Container = styled.section`
  overflow: hidden;
`

const HeroImageContainer = styled(WidthContainer)`
  padding-left: 0;
  padding-right: 0;

  @media screen and (min-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 5rem;
  }
`

const HeroImage = styled(Img)`
  height: 100vh;

  @media (min-width: 768px) {
    height: auto;
  }
`

const Title = styled.h1`
  margin: 2rem 0;
  font-size: 2.25rem;
  line-height: 1.4;
  text-transform: uppercase;
  position: relative;
  color: ${props => props.theme.textColorEmphasis};

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 4rem;
    background: ${props => props.theme.textColorEmphasis};
    height: 5px;
    bottom: -0.8rem;
  }
`

const Subtitle = styled.h2`
  margin: 2rem 0 0;
  font-weight: 500;
  font-size: 1.25rem;
`

const Text = styled.div`
  //margin-top: 0.5rem;

  p {
    margin: 1rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
`

const Content = styled(WidthContainer)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const ContentContainer = styled.div`
  padding-left: 1rem;

  @media screen and (min-width: 576px) {
    padding-left: 2rem;
    padding-right: 1rem;
  }
`

const MetasContainer = styled.div`
  margin-top: 2rem;
`

const Metas = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem;
  }
`

const Meta = styled.div`
  margin-top: 1.5rem;

  &:first-child {
    margin-top: 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 0;
    padding: 0.5rem;
    width: 50%;
  }
`

const MetaContentContainer = styled.div`
  ////margin-top: 0.5rem;
  //&:first-child {
  //  margin-top: 0;
  //}
`

const MetaTitle = styled(MetaContentContainer)`
  //font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
`

const MetaText = styled(MetaContentContainer)`
  font-size: 0.875rem;

  ul {
    margin: 0;
    padding-left: 1rem;
  }

  p {
    margin: 1rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
`

const MetaImages = styled.div`
  margin: -0.5rem;
  display: flex;
`

const MetaImageContainer = styled.div`
  padding: 0.5rem;
`

const Hero = ({ image, title, subtitle, text, projectMetas }) => {
  return (
    <Container>
      <HeroImageContainer>
        <HeroImage
          alt={image.description}
          fluid={image.localFile.childImageSharp.fluid}
          draggable={false}
        />
      </HeroImageContainer>
      <Content>
        <ContentContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Text>{documentToReactComponents(text.json)}</Text>
          <MetasContainer>
            <Metas>
              {projectMetas &&
                projectMetas.map((projectMeta, index) => {
                  const { title, text, images } = projectMeta
                  return (
                    <Meta key={index}>
                      {title && <MetaTitle>{title}</MetaTitle>}
                      {text && (
                        <MetaText>
                          {documentToReactComponents(text.json, {
                            renderNode: {
                              ...openLinkInNewTab,
                            },
                          })}
                        </MetaText>
                      )}
                      {images && (
                        <MetaContentContainer>
                          <MetaImages>
                            {images.map((image, index) => (
                              <MetaImageContainer key={index}>
                                <Img
                                  alt={image.description}
                                  fixed={image.localFile.childImageSharp.fixed}
                                  style={{
                                    display: "block",
                                  }}
                                />
                              </MetaImageContainer>
                            ))}
                          </MetaImages>
                        </MetaContentContainer>
                      )}
                    </Meta>
                  )
                })}
            </Metas>
          </MetasContainer>
        </ContentContainer>
      </Content>
    </Container>
  )
}

export default Hero
