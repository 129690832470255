import React from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer"

const Container = styled.section`
  overflow: hidden;
  margin-top: 3rem;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 3rem;
  }
`

const TitleContainer = styled(WidthContainer)``

const InnerContainer = styled.div`
  position: relative;
  padding: 2rem;
  width: fit-content;
  margin: auto;

  @media (min-width: 768px) {
    padding: 5rem;
  }
`

const QuoteIcon = styled.img`
  position: absolute;

  top: ${props => (props.isLeft ? `0.5rem` : `inherit`)};
  bottom: ${props => (props.isRight ? `0.5rem` : `inherit`)};
  left: ${props => (props.isLeft ? `0.5rem` : `inherit`)};
  right: ${props => (props.isRight ? `0.5rem` : `inherit`)};
`

const Title = styled.div`
  text-align: center;
  margin: 1rem 2rem 0;
  position: relative;
  font-size: 1.5rem;
  font-weight: 500;
  font-style: italic;
  color: ${props => props.theme.textColorEmphasis};
  //text-transform: uppercase;
  //line-height: 2;

  &:last-child {
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }
`

const Author = styled.div`
  text-align: center;
  margin: 0.5rem 3rem 0;
`

const SectionQuote = ({ section }) => {
  const { quote, author } = section
  return (
    <Container>
      <TitleContainer>
        <InnerContainer>
          <QuoteIcon src={`/double_quote_open.svg`} isLeft={true} />
          <Title>{documentToReactComponents(quote.json)}</Title>
          {author && <Author>{author.author}</Author>}
          <QuoteIcon src={`/double_quote_close.svg`} isRight={true} />
        </InnerContainer>
      </TitleContainer>
    </Container>
  )
}

export default SectionQuote
