import React from "react"
import { INLINES } from "@contentful/rich-text-types"

export const openLinkInNewTab = {
  [INLINES.HYPERLINK]: (node, children) => {
    // If you are using contenful.js sdk, the referenced entry is resolved
    // automatically and is available at `node.data.target`.
    // const referencedEntry = getEntryWithId(node.data.target.sys.id);
    return (
      <a href={`${node.data.uri}`} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  },
}
